import React from 'react';

function Resource() {
  return (
    <div className="ts-callout">
      <div className="container-fluid ts-section">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 ts-call-to-action">
              <h4>The Postman COVID-19 API Resource Center</h4>
              <p>A fast-growing list of APIs that help those on the front lines&mdash;including health care professionals, researchers, and government experts—that need quick, easy access to real-time critical data.</p>
              <a href="https://covid-19-apis.postman.com/" className="btn btn-outline-dark ts-button">View COVID-19 API Resource Center</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Resource;
